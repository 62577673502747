import React from "react";

import useIsMobile from "../../../utils/hooks/useIsMobile";

import Button from "../../ui/button";

import Logo from "../../../assets/images/Logo.png";
import IconFacebook from "../../../assets/images/social-facebook.png";
import IconLinkedin from "../../../assets/images/social-linkedin.png";
import FooterBg from "../../../assets/images/footer_bg.png";
import FooterBgMobile from "../../../assets/images/footer_bg_mobile.png";

export default function Footer() {
  const isMobile = useIsMobile();

  return (
    <footer>
      <div
        className="cta-section text-center bg-no-repeat bg-cover bg-top-center py-20 md:py-16"
        style={{
          backgroundImage: `url(${!isMobile ? FooterBg : FooterBgMobile})`,
        }}
      >
        <div className="max-w-[375px] md:max-w-[450px] mx-auto text-white">
          <h2 className="text-heading4 px-6">
            Ready to find your next career step?
          </h2>
          <p className="text-paragraph my-6 px-10 md:px-2">
            Join Wandel today, and let’s make job searching simpler, faster, and
            more rewarding.
          </p>
          <a href="/onboarding">
            <Button variant="primary" className="h-12 w-40">
              Join Now
            </Button>
          </a>
        </div>
      </div>
      <div className="footer-menu-section bg-[#FAFAFA]">
        <div className="container lg:px-24 py-14 md:py-24">
          <div className="flex flex-col md:flex-row items-center justify-between gap-y-16">
            <div className="footer-logo">
              <img
                src={Logo}
                alt="Footer Logo"
                className="w-[134px] ml-auto mr-auto md:ml-0 md:mr-auto"
              />
              <p className="text-xs mt-3">
                All Rights Reserved &copy; Wandel 2024
              </p>
            </div>
            <div className="footer-menu">
              <ul className="flex flex-col md:flex-row gap-10 md:gap-4 lg:gap-12">
                <li>
                  <a href="/about-us">
                    <span className="text-base text-black hover:text-primary-100">
                      About us
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.wandel.com/privacy-policy" target="_blank">
                    <span className="text-base text-black hover:text-primary-100">
                      Privacy Policy
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.wandel.com/imprint" target="_blank">
                    <span className="text-base text-black hover:text-primary-100">
                      Impressum
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-follow">
              <div className="flex items-center gap-4">
                <p className="text-base">Follow us</p>
                <a
                  href="https://www.facebook.com/people/Wandelcom/61565893912576/"
                  className="w-10 h-10 flex items-center justify-center bg-[#EEEEFF] rounded-full"
                >
                  <img src={IconFacebook} alt="Facebook" />
                </a>
                <a
                  href="https://de.linkedin.com/company/wandel-skilled-worker-platform"
                  className="w-10 h-10 flex items-center justify-center bg-[#EEEEFF] rounded-full"
                >
                  <img src={IconLinkedin} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
