import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Context from "../../utils/context";
import Button from "../../components/ui/button";
import Label from "../../components/ui/label";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters long").required("Password is required"),
});

export default function SignIn() {
  const context = useContext(Context);
  const location = useLocation();
  const { t } = useTranslation("auth");

  const [errorMessage, setErrorMessage] = useState("");

  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  let returnTo = location.state?.return_to || "/dashboard";

  const onSubmit = (data) => {
    context.auth.userSignIn(
      data.email,
      data.password,
      (response) => {
        // history.push(returnTo);
        window.location.href = "/dashboard";

      },
      (error) => {
        setErrorMessage(error.message || "An error occurred. Please try again.");
      }
    );
  };

  return (
    <div className="mt-10 max-w-[460px] mx-auto px-4">
      <h3 className="text-[30px] leading-[36px] font-medium mt-8">
        {t("signInTitle")}
      </h3>

      <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-y-6">
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
              {t("emailLabel")}
            </label>
            <input
              type="email"
              id="email"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              {...register("email")}
            />
            <span className="text-red-700 mt-1 text-xs">
              {errors.email?.message}
            </span>
          </div>

          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
              {t("passwordLabel")}
            </label>
            <input
              type="password"
              id="password"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              {...register("password")}
            />
            <span className="text-red-700 mt-1 text-xs">
              {errors.password?.message}
            </span>
          </div>

          {errorMessage && (
            <div className="text-red-600 text-sm mt-2">
              <span>{errorMessage}</span>
            </div>
          )}

          <div className="mt-6">
            <Button
              variant="primary"
              className="w-full"
              type="submit"
              disabled={!isValid}
            >
              {t("signInTitle")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

