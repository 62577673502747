import React, { useState, useEffect, useContext } from "react";

import Button from "../../ui/button";

import Logo from "../../../assets/images/Logo.png";
import LogoSecondary from "../../../assets/images/Logo_secondary.png";
import Flag_GB from "../../../assets/images/flag_gb.svg";
import Flag_DE from "../../../assets/images/flag_DE.svg";
import Context from "../../../utils/context";

export default function Header({ isPrimary = true }) {
  const [activeNav, setActiveNav] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const context = useContext(Context);

  const signOut = () => {
    context.auth.userLogOut();
  };
  const toggleNavbar = () => {
    setActiveNav((prev) => !prev);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <header className="fixed left-0 top-0 w-full z-30">
      <nav className={`relative z-30 w-full `}>
        <section
          className={`mx-auto flex h-14 w-full items-center justify-between px-5 md:mx-auto md:h-20 md:px-10  lg:h-[108px] transition-all duration-300 ${scrolling ? "drop-shadow-md bg-white lg:h-[90px]" : ""
            }`}
        >
          <div className="flex items-center gap-1 text-center md:gap-8 lg:gap-12">
            <a href="/">
              {isPrimary || scrolling ? (
                <img
                  alt="Wandel Logo"
                  decoding="async"
                  data-nimg="1"
                  className="w-[100px] cursor-pointer md:w-[134px]"
                  src={Logo}
                />
              ) : (
                <img
                  alt="Wandel Logo"
                  decoding="async"
                  data-nimg="1"
                  className="w-[100px] cursor-pointer md:w-[134px]"
                  src={LogoSecondary}
                />
              )}
            </a>
          </div>
          <div className="flex h-10 items-center gap-1 md:gap-5">
            <div className="dropdown mr-5">
              <button
                className="hidden md:flex flex cursor-pointer items-center gap-2"
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                type="button"
              >
                <span
                  className={`text-xs font-normal ${isPrimary || scrolling ? "text-black" : "text-white"
                    } md:text-sm lg:block`}
                >
                  {context.auth.getCurrentUser().email}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className={`${isPrimary || scrolling ? "text-black" : "text-white"
                    }`}
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>

              <div id="dropdown" className="hidden">
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200 bg-white rounded-md p-2 flex flex-col shadow-lg"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li className={`p-2`}>
                    <a href="#" onClick={signOut}>
                      <span className="flex items-center gap-2 text-black hover:text-primary-100">
                        <span className="hidden text-xs font-normal  md:text-sm lg:block">
                          Log out
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <button
              className={`hamburger-button cursor-pointer text-[25px] md:hidden ${activeNav ? "open" : ""
                }`}
              onClick={() => toggleNavbar()}
            >
              <span className="icon"></span>
            </button>
          </div>
        </section>
        <div className={`sidebar ${activeNav ? "open" : ""}`}>
          <div className="mt-2 flex w-full items-center justify-end">
            <button
              className="cursor-pointer"
              onClick={() => setActiveNav(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <ul className="mt-4 flex flex-col gap-4">
            <li>
              <span className="text-[24px] font-[400] text-black hover:text-primary-100">
                {context.auth.getCurrentUser().email}
              </span>
            </li>
            <li>
              <a href="#" onClick={signOut}>
                <span className="text-[16px] font-[400] text-[#7B8495] hover:text-primary-100">
                  Log out
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
