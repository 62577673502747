import { toast } from 'react-toastify';

export const objToQueryString = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        objToQueryString(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}

export const compact = (obj) => {
  return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
}

export const notifySuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const notifyError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const availableNextStatuses = (currentStatus, inModal) => {
  if (currentStatus === 'Not Started') {
    if (inModal) {
      return ['Pick From Finished Goods', 'Pick From PNB', 'Pick From CNP', 'Build On Laser', 'Canceled', 'Await Shipping', 'On Hold'];
    }
    return ['Canceled', 'On Hold'];
  }

  if (currentStatus === 'On Hold') {
    return ['Not Started'];
  }

  if (currentStatus === 'Pick From Finished Goods') {
    return [
      'Not Started',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Pick From PNB') {
    return [
      'Not Started',
      'Boxing Shopping Cart',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Boxing Shopping Cart') {
    return [
      'Not Started',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Pick From CNP') {
    return [
      'Not Started',
      'Printing Shopping Cart',
      'Air Drying',
      'Boxing Shopping Cart',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Printing Shopping Cart') {
    return [
      'Not Started',
      'Air Drying',
      'Boxing Shopping Cart',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Air Drying') {
    return [
      'Not Started',
      'Boxing Shopping Cart',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Build On Laser') {
    return [
      'Not Started',
      'Laser Shopping Cart',
      'Printing Shopping Cart',
      'Air Drying',
      'Boxing Shopping Cart',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Laser Shopping Cart') {
    return [
      'Not Started',
      'Printing Shopping Cart',
      'Air Drying',
      'Boxing Shopping Cart',
      'In Multimatch',
      'In Singles',
      'Await Shipping',
      'Canceled'
    ];
  }


  if (currentStatus === 'In Multimatch' || currentStatus === 'In Singles') {
    return [
      'Not Started',
      'Await Shipping',
      'Canceled'
    ];
  }

  if (currentStatus === 'Await Shipping') {
    return [
      'Not Started',
      'Canceled'
    ];
  }

  if (currentStatus === 'Canceled') {
    return [
      'Not Started',
    ];
  }

  return gon.statuses;
}
