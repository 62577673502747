import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import { StepProvider } from "./context/onboardingStepContext";
import { LanguageProvider } from "./context/LanguageContext";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>  
      <StepProvider>
        <App />
      </StepProvider>
    </LanguageProvider>
  </React.StrictMode>
);

