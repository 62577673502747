import React from "react";

import HomeLayouts from "../../components/layouts/HomeLayouts";
import JobBannerSection from "./JobBannerSection";
import JobListing from "./JobListing";
import Industries from "./Industries";
import { useTranslation } from "react-i18next";

export default function Jobs() {

  return (
    <HomeLayouts isPrimary={false}>
      <JobBannerSection />
      <JobListing />
      <Industries />
    </HomeLayouts>
  );
}
