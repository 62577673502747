import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/ui/button";
import { useStep } from "../../context/onboardingStepContext";
import Stepper from "../../components/partials/stepper";
import S3FileInput from "../../components/ui_kit/s3_file_input";

import ContactInformation from "./forms/ContactInformation";
import IndustryInfo from "./forms/IndustryInfo";
import EmploymentHistory2 from "./forms/EmploymentHistory2";
import Location from "./forms/Location";
import Success from "./forms/Success";
import { useUserIdentifier } from "../../context/userIdentifierContext";
import axios from "axios";
import Cookies from 'js-cookie';

import IconUpload from "../../assets/images/icon_upload.svg";

export default function OnboardingStepRenderer() {
  const onboardingInfo = JSON.parse(localStorage.getItem("onboardingInfo"));

  const { step, setStep } = useStep();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation("onboarding");
  const currentLanguage = i18n.language;

  const nextStep = () => {
    setStep(1);
  };

  const handleFileUpload = () => {
    inputRef.current.click();
  };

  const handleRedirectButtonClick = () => {
    window.location.href = "/dashboard";
  }

  const handleChange = (f) => {
    axios.post("/api/v1/notifications", {
      message: `User \`${Cookies.get('userIdentifier')}\` submitted resume.`,
      info: { resume: f },
    });

    axios.post("/welcome/candidate", {
      ...onboardingInfo,
      resume: f
    }).then(() => {
      // window.location.href = "/dashboard";
      setStep(6);
    }).catch(error => {
      console.error("Error submitting resume: ", error);
    })
  }

  return (
    <div>
      {step === 5 ? (
        <div className="px-12">
          <div className="pt-20 pb-40 max-w-[480px] mx-auto text-center">
            <h2 className="text-center text-heading4 md:text-[40px] md:leading-[48px] tracking-tighter text-black md:mt-[224px]">
              {t("uploadResumeTitle")}
            </h2>
            <p className="text-heading6 mt-9">{t("uploadResumeSubtitle")}</p>
            <S3FileInput
              onChange={handleChange}
              name={`resume`}
              accept={`*`}
              ref={inputRef}
              className={"hidden"}
              setIsLoading={setIsLoading}
            />
            <Button
              className="w-60 mt-9"
              variant="primary"
              onClick={handleFileUpload}
              disabled={isLoading}
            >
              <span className="flex items-center gap-2">
                <img src={IconUpload} alt="" />
                {t("uploadResumeButton")}
              </span>
            </Button>
            {/* <div>
              <Button
                className="w-60 mt-9 border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-blue-900 hover:border-blue-900"
                variant="secondary"
                onClick={handleRedirectButtonClick}
              >
                <span className="flex items-center gap-2">
                  {t("redirectDashboard")}
                </span>
              </Button>
            </div> */}
          </div>
        </div>
      ) : null}

      <div className="px-8 md:px-16">
        {/* Stepper */}
        {step !== 0 && step !== 5 && step !== 6 ? (
          <Stepper step={step} setStep={setStep} />
        ) : null}

        {step === 1 ? <IndustryInfo /> : null}

        {step === 2 ? <EmploymentHistory2 /> : null}

        {step === 3 ? <Location /> : null}

        {step === 4 ? <ContactInformation /> : null}

        {step === 6 ? <Success /> : null}
      </div>
    </div>
  );
}
