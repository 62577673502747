import React from "react";
import DashboardLayouts from '../../components/layouts/DashboardLayouts';
import JobListing from "../jobs/JobListing";

export default function Dashboard({ }) {
  return (
    <DashboardLayouts>
      <div className="home-banner bg-cover bg-bottom bg-no-repeat pt-12 md:pt-24">
        <JobListing />
      </div>
    </DashboardLayouts>
  )
}
